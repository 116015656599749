import React from 'react'
import { MDBRow, MDBCol } from 'mdbreact'
import Img from 'gatsby-image'
import Link from 'gatsby-link'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronRight } from '@fortawesome/pro-regular-svg-icons'

class ColumnAlternating extends React.Component {
  render() {
    return (
      <MDBRow className={`about-item align-items-center ${ this.props.placement === 'right' ? 'flex-row-reverse' : '' }`} >
        <MDBCol lg={this.props.imageCol ? this.props.imageCol : '6'} className="order-2 order-lg-1">
          <Img
            fluid={this.props.imageUrl}
            alt={this.props.imageAltText}
            className="rounded"
          />
        </MDBCol>
        <MDBCol lg={this.props.textCol ? this.props.textCol : '6'} className="mt-4 mt-lg-0 mb-4 mb-lg-0 order-1 order-lg-2" >
          <h3 className={ this.props.titleClass ? this.props.titleClass : 'font-alt font-w-400 letter-spacing-1 mb-3 title-xs-medium title-medium' } >
            {this.props.title}
          </h3>
          {this.props.subtitle && (
            <h4 className="font-alt font-w-700 letter-spacing-1 mb-4 title-xs-medium title-medium">
              {this.props.subtitle}
            </h4>
          )}
          <div className="mt-3 font-w-400 text-medium" style={{ whiteSpace: 'pre-wrap' }} dangerouslySetInnerHTML={{ __html: this.props.description }} />
          {this.props.link ? (
            this.props.link.charAt(0) == '/' ? (
              <p className="pt-3">
                <Link
                  to={this.props.link}
                  // className={`mt-4 ${this.props.colour}`}
                  className="mt-4 effect"
                  aria-label={this.props.title}
                >
                  {/* <FontAwesomeIcon icon={faChevronRight} /> */}
                  {this.props.linktext}
                </Link>
              </p>
            ) : (
              <a
                href={this.props.link}
                className={`mt-4 ${this.props.colour}`}
                target="_blank"
                rel="noopener"
                aria-label={this.props.title}
              >
                <FontAwesomeIcon icon={faChevronRight} />
              </a>
            )
          ) : null}{' '}
          {/* {this.props.linktext} */}
        </MDBCol>
      </MDBRow>
    )
  }
}

export default ColumnAlternating