import React, { Fragment } from 'react'
import { graphql } from 'gatsby'
import { MDBContainer, MDBRow, MDBCol, MDBAnimation } from 'mdbreact'
import Layout from '../../components/layout'
import SEO from '../../components/seo'
import Hero from '../../components/heroSubpage'
import ColumnAlternating from '../../components/columnAlternating'
import Card from '../../components/card'
import Cta from '../../components/cta'

export default ({ data, location }) => {
  const post = data.markdownRemark
  return (
    <Layout>
      <div id="sub-page">
      <SEO
          title={post.frontmatter.meta.title}
          description={post.frontmatter.meta.description}
          url={data.site.siteMetadata.siteUrl + location.pathname} 
          image={data.site.siteMetadata.siteUrl + post.frontmatter.image.childImageSharp.fluid.src}          
        />

        <Hero
          class="intro-65"
          image={post.frontmatter.image.childImageSharp.fluid}
          foreimage={null}
          imageAltText={post.frontmatter.alttext}
          title={post.frontmatter.title}
          subtitle={post.frontmatter.subtitle}
          ctatext={post.frontmatter.ctatext}
          ctaslug={post.frontmatter.ctaslug}
          type="root"
        />
      </div>

      <main>
        <section className="bg-white">
          <MDBAnimation reveal type="fadeIn" duration="1587ms" count={1}>
            <MDBContainer>
              <MDBRow>
                <MDBCol>
                  <div
                    className="font-w-400 text-xs-extra-large text-opening-para"
                    dangerouslySetInnerHTML={{ __html: post.frontmatter.openingpara }}
                  />
                  <div
                    className="mt-3 richtext divlink"
                    dangerouslySetInnerHTML={{ __html: post.html }}
                  />
                </MDBCol>
              </MDBRow>
            </MDBContainer>
          </MDBAnimation>
        </section>

        {post.frontmatter.section ? (
          <section className="bg-white-grey-gradient">
            {/* <MDBAnimation reveal type="fadeIn" duration="1587ms" count={1}> */}
              <MDBContainer>
                <div className="about-overview">
                  {post.frontmatter.section.map((sections, index) => {
                    return (
                      <ColumnAlternating
                        key={index}
                        textCol={'7'}
                        imageCol={'5'}
                        title={sections.title}
                        subtitle={sections.subtitle}
                        subtitlecolour={ sections.subtitlecolour != 'none' ? sections.subtitlecolour : 'text-very-dark-blue' }
                        description={sections.description}
                        imageUrl={sections.image.childImageSharp.fluid}
                        imageAltText={sections.alttext}
                        placement={sections.placement}
                        titleClass="font-alt font-w-400 letter-spacing-1 mb-3 title-xs-medium title-medium"
                        link={sections.btnlink}
                        linktext={sections.linktext}
                        colour={sections.btncolour}
                      />
                    )
                  })}
                </div>
              </MDBContainer>
            {/* </MDBAnimation> */}
          </section>
        ) : (
          ''
        )}

        <section className="bg-gray-light">
          <MDBAnimation reveal type="fadeIn" duration="1587ms" count={1}>
            <MDBContainer>
              <h3 className="font-alt font-w-700 letter-spacing-1 mb-3 title-xs-medium title-large">
                {post.frontmatter.indresources.title}
              </h3>
              {post.frontmatter.indresources.indres && (
                <MDBRow className="pt-5">
                  <>
                    {post.frontmatter.indresources.indres.map((industryres, index) => {
                      return (
                        <Fragment key={index}>
                          <Card
                            collg="4"
                            colmd="6"
                            height="7.5rem"
                            title={industryres.title}
                            subtitle={industryres.subtitle}
                            description={industryres.description}
                            imageUrl={industryres.image.childImageSharp.fluid}
                            imageAltText={industryres.alttext}
                            placement={industryres.placement}
                            link={industryres.link}
                            descriptionClass="text-card-small"
                          />
                        </Fragment>
                      )
                    })}
                  </>
                </MDBRow>
              )}
            </MDBContainer>
          </MDBAnimation>
        </section>

        <section className="bg-blue-green-gradient">
          <MDBAnimation reveal type="fadeIn" duration="1587ms" count={1}>
            <MDBContainer>
              <Cta
                ctatitle={post.frontmatter.cta.title}
                ctadescription={post.frontmatter.cta.description}
                ctalink={post.frontmatter.cta.link}
                ctalinktext={post.frontmatter.cta.linktext}
              />
            </MDBContainer>
          </MDBAnimation>
        </section>
      </main>
    </Layout>
  )
}

export const query = graphql`
  query {
    site {
      siteMetadata {
        title
        siteUrl
      }
    }      
    markdownRemark(frontmatter: { name: { eq: "industries" } }) {
      frontmatter {
        meta {
          title 
          description 
        }
        title
        subtitle
        image {
          childImageSharp {
            fluid(maxWidth: 1920, quality: 90) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        ctatext
        ctaslug
        alttext
        openingpara 
        section {
          title
          subtitle
          description
          image {
            childImageSharp {
              fluid(maxWidth: 540, quality: 90) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          placement
          alttext
          btnlink
          linktext
          btncolour
        }
        indresources {
          title
          indres {
            title
            subtitle
            description
            image {
              childImageSharp {
                fluid(maxWidth: 600, quality: 90) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
            placement
            alttext
            link
          }
        }
        cta {
          title
          description
          linktext
          link
        }
      }
      html
    }
  }
`
